<template>
     <recess-modal variant="variant2" :show-modal="isModalVisible" @close="closeModal">
       <template slot="modal-title">
            <h3 class="qa-order-status-modal-title">  {{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.Title }}  </h3>
            <p> {{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.OrderNumber }} <span class="u-fw-semi-bold">{{ orderNumber }}</span></p>
        </template>

        <template slot="modal-body">     
            <recess-card class="mt-3" variant="variant2" v-for="(currentOrderData, index) in orderHistoricalData" :key="index">               
                <div> 
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center mr-3">
                            <p class="mr-1 mb-0 u-fw-semi-bold">{{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.Status }}: </p> <span>{{ currentOrderData.statusDisplayValue }}</span> 
                        </div>

                        <div class="d-flex align-items-center mr-3">
                            <p class="mr-1 mb-0  u-fw-semi-bold">{{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.Date }}: </p> <span> {{ formatDateTime(currentOrderData.dateCreated) }}</span> 
                        </div>
                    </div> 

                    <div v-if="currentOrderData.message">
                        <recess-divider show-line />
                        <p class="u-fw-semi-bold">{{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.Message }}</p>  
                        <span>{{ currentOrderData.message ? currentOrderData.message : '-' }}</span>
                    </div> 

                    <div v-if="currentOrderData.cancellationReasonDisplayValue">
                        <recess-divider show-line />
                        <p class="u-fw-semi-bold mb-0">{{PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.CancelationReason }}: </p> 
                        <span>{{ currentOrderData.cancellationReasonDisplayValue ? currentOrderData.cancellationReasonDisplayValue : '-' }}</span>  
                    </div> 

                    <div v-if="currentOrderData.adminCancellationReasonDisplayValue">
                        <recess-divider show-line />
                        <p class="u-fw-semi-bold">{{ PROVIDER.Order.OrderOverview.Popup.OrderHistoryStatus.AdminCancelationReason }}: </p> 
                        <span>{{ currentOrderData.adminCancellationReasonDisplayValue ? currentOrderData.adminCancellationReasonDisplayValue : '-' }}</span>  
                    </div>   
                </div>
            </recess-card> 
        </template>
    </recess-modal> 
</template>

<script>
import { SHARED } from '@/../../shared/constants/EdumsSharedConstants.json'
import { getItemById } from '@/../../shared/api/SharedClient'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { formatToDateTimeString } from '@/utils/dateTimeHelper'

export default {
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    selectedOrderData: {
      type: Object,
      default: () => ({ id: '', number: '' }),
    },
  },
  data() {
    return {
      orderHistoricalData: [],
      orderNumber: '',
      PROVIDER
    };
  },
  watch: {
    isModalVisible(newValue) {
      this.watchPropsChange(newValue);
    },
  },
  methods: {
    formatDateTime(isoDate){
      return formatToDateTimeString(isoDate);
    },
    async getOrderDetails(orderId) {
      try {
        const response = await getItemById(
          process.env.VUE_APP_ORDER_API_URL,
          SHARED.ApiCallPaths.orders,
          orderId,
          null,
          false
        );

        if (!response) return;
        this.orderHistoricalData = response.orderStatusTransitions;
      } catch (error) {
        console.error(
          'Something went wrong while retrieving order details',
          error
        );
      }
    },
    watchPropsChange(newValue) {
      if (
        newValue &&
        this.selectedOrderData.id &&
        this.selectedOrderData.number
      ) {
        this.getOrderDetails(this.selectedOrderData.id);
        this.orderNumber = this.selectedOrderData.number;
      }
    },

    closeModal() {
      this.orderHistoricalData = []; 
      this.orderNumber = ''; 
      this.$emit('close'); 
    },
  },
};
</script>


